require('@upjs/facile-validator');
import {
    Validator,
    faLang as fa,
    deLang as de,
    enLang as en,
    frLang as fr,
    itLang as it,
    zhLang as zh
} from '@upjs/facile-validator';
// Select the container element that contains the fields
const form = document.getElementById('request-form');

// Create an instance of Validator for the container element
const v = new Validator(form, {
    lang: de
});
$(document).keydown(function(event) {
    var keyCode = event.keyCode || event.which;
    if (keyCode === 116 || (event.ctrlKey && (keyCode === 82 || keyCode === 116))) {
        event.preventDefault();
    }
});
$('#request-form').on('submit', function (e) {
    e.preventDefault();
    changeLocale();
    // Call validate method to start validation
    v.validate();
    if(v.validate()){
        $('#to-step-submit').prop('disabled', true);
        $('.loading').removeClass('hidden');
        gRecaptchaCheck(form);
        history.pushState(null, null, location.href);
        window.onpopstate = function() {
            history.go(1);
        };
    }
    form.addEventListener('change', () => {
        v.validate();
    })
});


// Handle error-free validation
v.on('validation:success', () => {


    $('#to-step-submit').on('click', function (event) {
        event.preventDefault();
        $('#to-step-submit').prop('disabled', true);
        $('.loading').removeClass('hidden');

        history.pushState(null, null, location.href);
        window.onpopstate = function() {
            history.go(1);
        };
        gRecaptchaCheck(form);
    })

});

// Handle failed validation
v.on('validation:failed', () => {
    // console.log('Oops! There are some errors in the form.');
});

// Handle error validation
v.on('validation:error', (form, field, errors) => {
    // console.log('----------------------------------------');
    // console.log(field);
    // console.log('----------------------------------------');
    // errors.forEach(error => {
    //     console.log(error.args);
    //     console.log(error.message);
    //     console.log(error.rule);
    //     console.log(error.element);
    // });
});

function changeLocale() {
    switch (window.currentLocale) {
        case 'en':
            v.setLanguage(en);
            break;
        case 'de':
            v.setLanguage(de);
            break;
        case 'fr':
            v.setLanguage(fr);
            break;
        case 'it':
            v.setLanguage(it);
            break;
        case 'zh':
            v.setLanguage(zh);
            break;

    }

}
